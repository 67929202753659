import Image from 'next/image';
import { rem } from 'polished';
import styled from 'styled-components';
import { DESKTOP_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '../../constants';
import { breakpoints } from '../../styles/theme';

export const Wrapper = styled.div`
    padding: ${rem(20)};
    max-width: ${rem(1250)};
    margin: ${rem(MOBILE_HEADER_HEIGHT)} auto 0 auto;
    color: ${({ theme }) => theme.colors.black};

    @media (min-width: ${breakpoints.sm}px) {
        padding: ${rem(40)} ${rem(20)};
        margin: ${rem(DESKTOP_HEADER_HEIGHT)} auto 0 auto;
    }
`;

export const Top = styled.div`
    display: flex;
    flex-direction: column-reverse;
    gap: ${rem(30)};

    @media (min-width: ${breakpoints.sm}px) {
        flex-direction: row;
    }
`;

export const HeroImage = styled.img`
    width: 100%;
    height: auto;
    border-radius: ${rem(24)};

    @media (min-width: ${breakpoints.sm}px) {
        width: 55%;
    }
`;

export const Benefits = styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${rem(40)} 0 ${rem(20)} 0;
    flex-wrap: wrap;
    margin-top: ${rem(10)};
`;

export const About = styled.div`
    @media (min-width: ${breakpoints.md}px) {
        padding-left: ${rem(50)};
        width: 45%;
    }
`;

export const Benefit = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    width: 50%;
    margin-bottom: ${rem(40)};

    @media (min-width: ${breakpoints.sm}px) {
        width: 25%;
        margin-bottom: 0;
    }

    @media (min-width: ${breakpoints.lg}px) {
        flex-direction: row;
    }
`;

export const BenefitNumber = styled.span`
    font-size: ${rem(48)};
    font-family: ${({ theme }) => theme.fonts.fontSecondary};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
    padding-right: ${rem(15)};
    white-space: nowrap;
`;

export const BenefitLabel = styled.span`
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    text-align: center;
    line-height: ${rem(20)};

    @media (min-width: ${breakpoints.sm}px) {
        text-align: left;
    }
`;

export const Title = styled.h1`
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    font-size: ${({ theme }) => theme.fontSizes.h3};
    font-family: ${({ theme }) => theme.fonts.fontSecondary};
    margin-top: ${rem(20)};

    @media (min-width: ${breakpoints.sm}px) {
        font-size: ${({ theme }) => theme.fontSizes.h2};
        margin-top: 0;
    }
`;

export const Description = styled.p`
    color: ${({ theme }) => theme.colors.grey};
    font-size: ${({ theme }) => theme.fontSizes.h6};
    padding: ${rem(15)} 0;
    line-height: ${rem(30)};
`;
